import apiRequest from 'Utils/apiRequest'
import Router from 'next/router'
import browserStorageService from 'Services/browserStorageService'
import ModifyUrlParam from 'Utils/ModifyUrlParam'
import noAuthApiRequest from 'Utils/noAuthApiRequest'
import { createStore } from 'redux'

// make sure localstate is not used in server side
let localCourseState = {
  course: null,
  slug: null,
  modulePage: null,
  activeModule: null,
}

function Reducer(state, action) {
  if (typeof state === 'undefined' && action.type === 'RESET') {
    return { update: null }
  }
  switch (action.type) {
    case 'COURSE_UPDATE':
      return { ...state, update: action.update }
    default:
      return state
  }
}

const reduxCourseStore = createStore(Reducer)

function sendCourseUpdate(update) {
  reduxCourseStore.dispatch({
    type: 'COURSE_UPDATE',
    update,
  })
}

function getCourseContentHrefUrl(course = null) {
  // returns href and url
  const { modulePage, activeModule } = localCourseState
  let { slug } = localCourseState
  // ensure course params only works when passed
  if (course) slug = course.slug
  let url = ModifyUrlParam(`/course/${slug}/content`, 'modulePage', modulePage)
  url = ModifyUrlParam(url, 'activeModule', activeModule)
  return {
    href: {
      pathname: '/course/[slug]/content',
      query: { slug: localCourseState.slug, modulePage, activeModule },
    },
    url,
  }
}

function updateCourseContentUrl() {
  const data = getCourseContentHrefUrl()
  Router.push(data.href, data.url, { shallow: true })
}

export default {
  getAllSlugs() {
    return apiRequest
      .get('/school/course/allslugs/')
      .then((data) => data.result)
  },
  getLandingPageCourse(courseId) {
    return apiRequest
      .get(`/school/course/${courseId}/?type=landing`)
      .then((data) => data.result)
  },
  deleteCourse(courseId) {
    return apiRequest.delete(`/school/course/${courseId}/`, {
      showProgress: true,
    })
  },
  getCourseContent(slug, invalidateCache) {
    let p = null
    sendCourseUpdate(null)
    if (
      localCourseState.slug === slug &&
      !invalidateCache &&
      localCourseState.course &&
      typeof window !== 'undefined'
    ) {
      // use localstate resolve only on client side not on server side
      p = Promise.resolve(localCourseState.course)
    } else {
      localCourseState.slug = null
      localCourseState.course = null
      p = apiRequest
        .get(`/school/course/${slug}/?type=content`)
        .then((data) => {
          localCourseState.course = data.result
          localCourseState.slug = slug
          return data.result
        })
    }
    return p
  },
  getSchoolPublicCourses(page, invalidateCache) {
    let p = null
    let data = null
    if (!page || page === 1) {
      data = browserStorageService.get('courses.school.public')
    }
    if (data && !invalidateCache) {
      p = Promise.resolve(data)
    } else {
      p = noAuthApiRequest
        .get('/school/course/?type=public', {
          params: { page },
        })
        .then((newData) => {
          if (!page || page === 1) {
            browserStorageService.set('courses.school.public', newData)
          }
          return newData
        })
    }
    return p
  },
  getShareData(courseId) {
    return apiRequest.get(`/school/course/${courseId}/?type=shareURLs`)
  },
  getNotificationsData(courseId) {
    return apiRequest
      .get(`/school/course/${courseId}/?type=notificationsData`)
      .then((data) => data.result)
  },
  getEarningsData(courseId) {
    return apiRequest
      .get(`/school/course/${courseId}/?type=earnings`)
      .then((data) => data.result)
  },
  getMySchoolCourses(page, invalidateCache) {
    let p = null
    let data = null
    if (!page || page === 1)
      data = browserStorageService.get('courses.school.my')
    if (data && !invalidateCache) {
      p = Promise.resolve(data)
    } else {
      p = apiRequest
        .get('/school/course/?type=my', {
          params: { page },
        })
        .then((newData) => {
          if (!page || page === 1)
            browserStorageService.set('courses.school.my', newData)
          return newData
        })
    }
    return p
  },
  getAllSchoolCourses(query, page) {
    return apiRequest.get('/school/course/?type=all', {
      params: { page, query },
      hideProgress: Boolean(query),
    })
  },
  search(query, page) {
    // dont return empty for blank query as that can
    // be used to show all courses
    let decodedQuery = ''
    if (query) decodedQuery = decodeURIComponent(query)
    return apiRequest.get('/school/public/course/', {
      params: { page, query: decodedQuery },
    })
  },
  suggest(query) {
    return apiRequest.get('/school/public/course/', {
      params: { suggest: query },
    })
  },
  update(updateObj) {
    // format {object, delete}
    sendCourseUpdate(updateObj)
  },
  getStore() {
    return reduxCourseStore
  },
  getReviews(courseId, page) {
    return apiRequest.get(`/school/course/${courseId}/review/`, {
      params: { page },
    })
  },
  editReview(reviewId, r) {
    return apiRequest.patch(`/school/course/review/${reviewId}/`, r)
  },
  submitReview(courseId, r) {
    return apiRequest.put(`/school/course/${courseId}/review/`, r)
  },
  createCourse(b) {
    return apiRequest.put('/school/course/', b)
  },
  removeFaculty(courseId, userId) {
    return apiRequest.post(`/school/course/${courseId}/?type=removeFaculty`, {
      uid: userId,
    })
  },
  removeStudent(courseId, userId) {
    return apiRequest.post(`/school/course/${courseId}/?type=removeStudent`, {
      uid: userId,
    })
  },
  removeTA(courseId, userId) {
    return apiRequest.post(`/school/course/${courseId}/?type=removeTA`, {
      uid: userId,
    })
  },
  updateLastSeen(courseId) {
    return apiRequest.post(`/school/course/${courseId}/?type=lastSeen`, {})
  },
  startFreeTrial(courseId) {
    return apiRequest.post(
      `/school/course/${courseId}/?type=startFreeTrial`,
      {}
    )
  },
  getPopupBadge(courseId, cancelToken) {
    return apiRequest.get(`/school/course/${courseId}/?type=popupBadge`, {
      cancelToken,
      hideProgress: true,
    })
  },
  getLeaderboardConfig(courseId) {
    return apiRequest.get(`/school/course/${courseId}/?type=leaderboardConfig`)
  },
  toggleCustomLeaderboard(courseId) {
    return apiRequest.post(
      `/school/course/${courseId}/?type=toggleCustomLeaderboard`
    )
  },
  getStudents(courseId, query, page) {
    return apiRequest.get(`/school/course/${courseId}/?type=students`, {
      params: { query, page },
    })
  },
  getInstructors(courseId, query, page) {
    return apiRequest.get(`/school/course/${courseId}/?type=instructors`, {
      params: { query, page },
    })
  },
  preparePurchase(courseId, discountCode = '') {
    return apiRequest.post(
      `/school/course/${courseId}/?type=preparePurchase&discountCode=${discountCode}`
    )
  },
  joinCourse(courseId, data) {
    return apiRequest.post(`/school/course/${courseId}/?type=join`, data)
  },
  publishCourse(courseId) {
    return apiRequest.post(`/school/course/${courseId}/?type=publish`, {})
  },
  unpublishCourse(courseId) {
    return apiRequest.post(`/school/course/${courseId}/?type=unpublish`, {})
  },
  leaveCourse(courseId) {
    return apiRequest.post(
      `/school/course/${courseId}/?type=leave`,
      {},
      { showProgress: true }
    )
  },
  editCourse(courseId, data) {
    return apiRequest.patch(`/school/course/${courseId}/`, data)
  },
  setCourseState(newState) {
    // called from content page mount
    // used because if content page is ssred,
    // course is not set in course service
    localCourseState = newState
  },
  getCourseState() {
    return localCourseState
  },
  setModulePage(modulePage) {
    localCourseState.modulePage = modulePage
    localCourseState.activeModule = null
    updateCourseContentUrl()
  },
  setActiveModule(moduleId) {
    localCourseState.activeModule = moduleId
    updateCourseContentUrl()
  },
  getActivityLogs(courseId, page) {
    return apiRequest.get(`/school/course/${courseId}/activitylog/`, {
      params: { page },
    })
  },
  getStudentActivityLogs(courseId, studentId, page) {
    return apiRequest.get(`/school/course/${courseId}/activitylog/`, {
      params: { page, studentId },
    })
  },
  backToContentPage(course) {
    // in case this function is used in other places i am yet to find
    const data = getCourseContentHrefUrl(course)
    Router.push(data.href, data.url)
  },
  toggleFreeTrial(courseId, data) {
    return apiRequest.post(
      `/school/course/${courseId}/?type=toggleFreeTrial`,
      data
    )
  },
  getStudentReport(courseId, studentId) {
    return apiRequest
      .get(
        `/school/course/${courseId}/student/${studentId}/?type=progressReport`
      )
      .then((data) => data.result)
  },
  getStudentReportModules(courseId, studentId, page) {
    return apiRequest.get(
      `/school/course/${courseId}/student/${studentId}/?type=moduleProgress`,
      {
        params: { page },
      }
    )
  },
  getStudentWeeklyReports(courseId, studentId, page) {
    return apiRequest.get(
      `/school/course/${courseId}/student/${studentId}/?type=weeklyReports`,
      {
        params: { page },
      }
    )
  },
  getContentStats(courseId) {
    return apiRequest
      .get(`/school/course/${courseId}/?type=contentStats`)
      .then((data) => data.result)
  },
  getCollections() {
    return apiRequest.get('/school/course/collection/')
  },
  getLandingPageCollections() {
    return noAuthApiRequest
      .get('/school/course/collection/?type=landing')
      .then((data) => data.result)
  },
  createCollection(data) {
    return apiRequest.put('/school/course/collection/', data)
  },
  deleteCollection(collectionId) {
    return apiRequest.delete(`/school/course/collection/${collectionId}/`)
  },
  editCollection(collectionId, data) {
    return apiRequest.patch(`/school/course/collection/${collectionId}/`, data)
  },
  addCourseToCollection(collectionId, data) {
    return apiRequest.put(
      `/school/course/collection/${collectionId}/course/`,
      data
    )
  },
  getCollectionCourses(collectionId) {
    return apiRequest.get(`/school/course/collection/${collectionId}/course/`)
  },
  getCollectionCoursesForLanding(collectionId) {
    return noAuthApiRequest.get(
      `/school/course/collection/${collectionId}/course/?type=landing`
    )
  },
  removeCourseFromCollection(collectionCourseId) {
    return apiRequest.delete(
      `/school/course/collection/course/${collectionCourseId}/`
    )
  },
  getDiscountCodes(courseId) {
    return apiRequest.get(`/school/course/${courseId}/discountcode/`)
  },
  createDiscountCode(courseId, data) {
    return apiRequest.put(`/school/course/${courseId}/discountcode/`, data)
  },
  deleteDiscountCode(discountCodeId) {
    return apiRequest.delete(`/school/course/discountcode/${discountCodeId}/`)
  },
  checkDiscountCode(courseId, discountCodeName) {
    return apiRequest.get(
      `/school/course/${courseId}/?type=checkDiscountCode&discountCode=${discountCodeName}`
    )
  },
  getFreeTrialRecords(courseId, page) {
    return apiRequest.get(`/school/course/${courseId}/?type=freeTrialRecords`, {
      params: { page },
    })
  },
  getNotificationControls(courseId) {
    return apiRequest.get(`/school/course/${courseId}/notification-controls/`)
  },
  updateNotificationControls(courseId, data) {
    return apiRequest.patch(
      `/school/course/${courseId}/notification-controls/`,
      data
    )
  },
}
